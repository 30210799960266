import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>Unearthed Arcana</h2>
    <h3><a id="spell-points"></a>SPELL POINTS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
The spell point system presented here allows caster to more freely pick and choose
which spells they cast each day.
    <p>Every spellcaster has a reserve of spell points based on her class and level
(see <a style={{
        "color": "#579eb6"
      }} href="#table-spell-points-per-day">Table: Spell Points Per Day</a>). Characters also gain bonus spell points from
a high ability score (just as normal spellcasters would gain bonus spells from a
high ability score; see <a style={{
        "color": "#579eb6"
      }} href="#bonus-spell-ponts-and-bonus-spells">Bonus Spell Points and Bonus Spells</a>, below). These spell
points provide the magical power behind the caster's spells: She spends a number
of spell points appropriate to the spell's level to cast the spell (see <a style={{
        "color": "#579eb6"
      }} href="#casting-spells">Casting Spells</a>,
below). Once spent, spell points are expended until the caster has sufficient time
to rest and prepare new spells (see <a style={{
        "color": "#579eb6"
      }} href="#preparing-spells">Preparing Spells</a>, below).</p>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td style={{
            "width": "48%",
            "verticalAlign": "top"
          }}><b><a id="table-spell-points-per-day"></a>Table: Spell Points per Day</b>
            <table border="0" cellPadding="0" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th>Level</th>
                  <th>Bard</th>
                  <th>Cleric, Druid, Wizard</th>
                  <th>Ranger, Paladin</th>
                  <th>Sorcerer</th>
                </tr>
                <tr className="odd-row">
                  <td>1st</td>
                  <td>0</td>
                  <td>2</td>
                  <td>0</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>2nd</td>
                  <td>0</td>
                  <td>4</td>
                  <td>0</td>
                  <td>5</td>
                </tr>
                <tr className="odd-row">
                  <td>3rd</td>
                  <td>1</td>
                  <td>7</td>
                  <td>0</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>4th</td>
                  <td>5</td>
                  <td>11</td>
                  <td>0</td>
                  <td>14</td>
                </tr>
                <tr className="odd-row">
                  <td>5th</td>
                  <td>6</td>
                  <td>16</td>
                  <td>0</td>
                  <td>19</td>
                </tr>
                <tr>
                  <td>6th</td>
                  <td>9</td>
                  <td>24</td>
                  <td>1</td>
                  <td>29</td>
                </tr>
                <tr className="odd-row">
                  <td>7th</td>
                  <td>14</td>
                  <td>33</td>
                  <td>1</td>
                  <td>37</td>
                </tr>
                <tr>
                  <td>8th</td>
                  <td>17</td>
                  <td>44</td>
                  <td>1</td>
                  <td>51</td>
                </tr>
                <tr className="odd-row">
                  <td>9th</td>
                  <td>22</td>
                  <td>56</td>
                  <td>1</td>
                  <td>63</td>
                </tr>
                <tr>
                  <td>10th</td>
                  <td>29</td>
                  <td>72</td>
                  <td>4</td>
                  <td>81</td>
                </tr>
                <tr className="odd-row">
                  <td>11th</td>
                  <td>34</td>
                  <td>88</td>
                  <td>4</td>
                  <td>97</td>
                </tr>
                <tr>
                  <td>12th</td>
                  <td>41</td>
                  <td>104</td>
                  <td>9</td>
                  <td>115</td>
                </tr>
                <tr className="odd-row">
                  <td>13th</td>
                  <td>50</td>
                  <td>120</td>
                  <td>9</td>
                  <td>131</td>
                </tr>
                <tr>
                  <td>14th</td>
                  <td>57</td>
                  <td>136</td>
                  <td>10</td>
                  <td>149</td>
                </tr>
                <tr className="odd-row">
                  <td>15th</td>
                  <td>67</td>
                  <td>152</td>
                  <td>17</td>
                  <td>165</td>
                </tr>
                <tr>
                  <td>16th</td>
                  <td>81</td>
                  <td>168</td>
                  <td>20</td>
                  <td>183</td>
                </tr>
                <tr className="odd-row">
                  <td>17th</td>
                  <td>95</td>
                  <td>184</td>
                  <td>25</td>
                  <td>199</td>
                </tr>
                <tr>
                  <td>18th</td>
                  <td>113</td>
                  <td>200</td>
                  <td>26</td>
                  <td>217</td>
                </tr>
                <tr className="odd-row">
                  <td>19th</td>
                  <td>133</td>
                  <td>216</td>
                  <td>41</td>
                  <td>233</td>
                </tr>
                <tr>
                  <td>20th</td>
                  <td>144</td>
                  <td>232</td>
                  <td>48</td>
                  <td>249</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style={{
            "width": "4%"
          }}>
          </td>
          <td style={{
            "width": "48%"
          }}><b><a id="table-bonus-spell-points"></a>Table: Bonus Spell Points</b>
            <table border="0" cellPadding="0" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th></th>
                  <th colSpan="10">-Bonus Spell Points (by Maximum Spell Level)-</th>
                </tr>
                <tr>
                  <th>Score</th>
                  <th>0</th>
                  <th>1st</th>
                  <th>2nd</th>
                  <th>3rd</th>
                  <th>4th</th>
                  <th>5th</th>
                  <th>6th</th>
                  <th>7th</th>
                  <th>8th</th>
                  <th>9th</th>
                </tr>
                <tr className="odd-row">
                  <td>12-13</td>
                  <td>-</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>14-15</td>
                  <td>-</td>
                  <td>1</td>
                  <td>4</td>
                  <td>4</td>
                  <td>4</td>
                  <td>4</td>
                  <td>4</td>
                  <td>4</td>
                  <td>4</td>
                  <td>4</td>
                </tr>
                <tr className="odd-row">
                  <td>16-17</td>
                  <td>-</td>
                  <td>1</td>
                  <td>4</td>
                  <td>9</td>
                  <td>9</td>
                  <td>9</td>
                  <td>9</td>
                  <td>9</td>
                  <td>9</td>
                  <td>9</td>
                </tr>
                <tr>
                  <td>18-19</td>
                  <td>-</td>
                  <td>1</td>
                  <td>4</td>
                  <td>9</td>
                  <td>16</td>
                  <td>16</td>
                  <td>16</td>
                  <td>16</td>
                  <td>16</td>
                  <td>16</td>
                </tr>
                <tr className="odd-row">
                  <td>20-21</td>
                  <td>-</td>
                  <td>2</td>
                  <td>5</td>
                  <td>10</td>
                  <td>17</td>
                  <td>26</td>
                  <td>26</td>
                  <td>26</td>
                  <td>26</td>
                  <td>26</td>
                </tr>
                <tr>
                  <td>22-23</td>
                  <td>-</td>
                  <td>2</td>
                  <td>8</td>
                  <td>13</td>
                  <td>20</td>
                  <td>29</td>
                  <td>40</td>
                  <td>40</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr className="odd-row">
                  <td>24-25</td>
                  <td>-</td>
                  <td>2</td>
                  <td>8</td>
                  <td>18</td>
                  <td>25</td>
                  <td>34</td>
                  <td>45</td>
                  <td>58</td>
                  <td>58</td>
                  <td>58</td>
                </tr>
                <tr>
                  <td>26-27</td>
                  <td>-</td>
                  <td>2</td>
                  <td>8</td>
                  <td>18</td>
                  <td>32</td>
                  <td>41</td>
                  <td>52</td>
                  <td>65</td>
                  <td>80</td>
                  <td>80</td>
                </tr>
                <tr className="odd-row">
                  <td>28-29</td>
                  <td>-</td>
                  <td>3</td>
                  <td>9</td>
                  <td>19</td>
                  <td>33</td>
                  <td>51</td>
                  <td>62</td>
                  <td>75</td>
                  <td>90</td>
                  <td>107</td>
                </tr>
                <tr>
                  <td>30-31</td>
                  <td>-</td>
                  <td>3</td>
                  <td>12</td>
                  <td>22</td>
                  <td>36</td>
                  <td>54</td>
                  <td>76</td>
                  <td>89</td>
                  <td>104</td>
                  <td>121</td>
                </tr>
                <tr className="odd-row">
                  <td>32-33</td>
                  <td>-</td>
                  <td>3</td>
                  <td>12</td>
                  <td>24</td>
                  <td>38</td>
                  <td>56</td>
                  <td>78</td>
                  <td>104</td>
                  <td>119</td>
                  <td>136</td>
                </tr>
                <tr>
                  <td>34-35</td>
                  <td>-</td>
                  <td>3</td>
                  <td>12</td>
                  <td>27</td>
                  <td>48</td>
                  <td>66</td>
                  <td>88</td>
                  <td>114</td>
                  <td>144</td>
                  <td>161</td>
                </tr>
                <tr className="odd-row">
                  <td>36-37</td>
                  <td>-</td>
                  <td>4</td>
                  <td>13</td>
                  <td>28</td>
                  <td>49</td>
                  <td>76</td>
                  <td>98</td>
                  <td>124</td>
                  <td>154</td>
                  <td>188</td>
                </tr>
                <tr>
                  <td>38-39</td>
                  <td>-</td>
                  <td>4</td>
                  <td>16</td>
                  <td>31</td>
                  <td>52</td>
                  <td>77</td>
                  <td>110</td>
                  <td>136</td>
                  <td>166</td>
                  <td>200</td>
                </tr>
                <tr className="odd-row">
                  <td>40-41</td>
                  <td>-</td>
                  <td>4</td>
                  <td>16</td>
                  <td>36</td>
                  <td>57</td>
                  <td>84</td>
                  <td>117</td>
                  <td>156</td>
                  <td>186</td>
                  <td>220</td>
                </tr>
                <tr>
                  <td>42-43</td>
                  <td>-</td>
                  <td>4</td>
                  <td>16</td>
                  <td>36</td>
                  <td>64</td>
                  <td>91</td>
                  <td>124</td>
                  <td>163</td>
                  <td>208</td>
                  <td>242</td>
                </tr>
                <tr className="odd-row">
                  <td>44-45</td>
                  <td>-</td>
                  <td>5</td>
                  <td>17</td>
                  <td>37</td>
                  <td>65</td>
                  <td>101</td>
                  <td>134</td>
                  <td>173</td>
                  <td>218</td>
                  <td>269</td>
                </tr>
                <tr>
                  <td>46-47</td>
                  <td>-</td>
                  <td>5</td>
                  <td>20</td>
                  <td>40</td>
                  <td>68</td>
                  <td>104</td>
                  <td>148</td>
                  <td>187</td>
                  <td>232</td>
                  <td>283</td>
                </tr>
                <tr className="odd-row">
                  <td>48-49</td>
                  <td>-</td>
                  <td>5</td>
                  <td>20</td>
                  <td>45</td>
                  <td>73</td>
                  <td>109</td>
                  <td>156</td>
                  <td>205</td>
                  <td>250</td>
                  <td>301</td>
                </tr>
                <tr>
                  <td>50-51</td>
                  <td>-</td>
                  <td>5</td>
                  <td>20</td>
                  <td>45</td>
                  <td>80</td>
                  <td>116</td>
                  <td>160</td>
                  <td>212</td>
                  <td>272</td>
                  <td>323</td>
                </tr>
                <tr className="odd-row">
                  <td colSpan="11">and so on...</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <h5><a id="preparing-spells"></a>PREPARING SPELLS</h5>
With this variant, spellcasters still prepare spells as normal (assuming they normally
prepare spells). In effect, casters who prepare spells are setting their list of
"spells known" for the day. They need not prepare multiple copies of the same spell,
since they can cast any combination of their prepared spells each day (up to the
limit of their spell points).
    <p>For example, a 4th-level wizard with an Intelligence score of 16, when using
the spell point system, would prepare four 0-level spells, four 1st-level spells
(three plus her bonus spell for high Int) and three 2nd-level spells (two plus her
bonus spell for high Int). These spells make up her entire list of spells that she
can cast during the day, though she can cast any combination of them, as long as
she has sufficient spell points.</p>
    <h6><a id="bonus-spell-points-and-bonus-spells"></a>Bonus Spell Points and Bonus Spells</h6>
Any spellcaster who would normally receive bonus spells for a high ability-score
receives bonus spell points instead. In effect, the character can simply cast more
of her spells each day.
    <p>To determine the number of bonus spell points gained from a high ability score,
first find the row for the character's ability score on Table: Bonus Spell Points.
Use whichever ability score would normally award bonus spells for the character's
class (Wisdom for clerics and druids, Intelligence for wizards, and so forth).</p>
    <p>Next, find the coulumn for the highest level of spell the characrer is capable
of casting based on her class level (even if she doesn't have a high enough ability
score to cast spells of that level). At the point where the row and coulumn intersect,
you find the bonus spell points a character gains. This value can change each time
her ability score undergoes a permanent change (such as from an ability score increase
due to character level or one from a <a style={{
        "color": "#579eb6"
      }} href="spellsTtoZ.html#wish">wish</a> spell)
and each time her level changes.</p>
    <p> For example, the 4th-level wizard with an Intelligence score of 16 is capable
of casting 2nd-level spells. The number on Table: Bonus Spell Points at the intersection
of the 16-17 row and the 2nd column is 4, so she has 4 extra spell points each day (in
addition to the 11 points she gets from being a 4th-level wizard). If her Intelligence
were increased to 20 because of <a style={{
        "color": "#579eb6"
      }} href="spellsFtoG.html#foxs-cunning">fox's cunning</a>
spell or a Headband of Intellect +4, she wouldn't gain any additional bonus spell
points, since those effects produce temporary changes, not permanent changes. However,
when she reaches 5th level, her bonus spell points would increase from 4 to 9 (since
she is now capable of casting 3rd-level spells and thus uses that column), and her
overall total would increase from 15 to 25.</p>
    <p>A character who would normally receive bonus spells from a class feature (such as
from wizard specialization or access to a domain) can instead prepare extra spells
of the appropriate levels, domains, and/or shcools. The character doesn't get any
extra spell points (and thus can't cast any more spell than normal), but the added
flexibility of being able to use the bonus spell more than once per day makes up
for that.</p>
    <p>For instance, a specialist wizard can prepare one extra spell from the chosen
school of each spell level that she can cast. A cleric can prepare one domain spell
(chosen from amond his domain spells available) of each spell level that he can
cast.</p>
    <p>For example, if the previous 4th-level wizard were an evoker, she could prepare
one additional spell per level, but that spell would have to be from the evocation
school. Once it is prepared, she can use that spell just like any of her other spells,
casting it as often as she has spell points.</p>
    <p>Another example: At 1st level, a cleric gains a bonus 1st-level spell, which
must be selected from one of his two domains. Once it is prepared, he can use that
domain spell just like any of his other spells, casting it as often as he has spell
points.</p>
    <p>For class features that grant bonus spells of a nonfixed spell lecel (such as
the <a style={{
        "color": "#579eb6"
      }} href="dragonDisciple.html">dragon disciple's</a> bonus spells), the character
instead gains a number of bonus spell points equal to twice the highest spell level
he can cast, minus 1 (minimun 1 point) each time he gains a bonus spell. This is a
fixed value - it doesn't increase later as the character gains levels - though later
rewards may be larger as appropriate to the character's spellcasting ability.</p>
    <p>For example, a 4th-level fighter/4th-level sorcerer who gains a level of <a style={{
        "color": "#579eb6"
      }} href="dragonDisciple.html">dragon disciple</a>
gets a bonus spell. Since the character is capable of casting 2nd-level spells,
she receives 3 bonus spells points (2*2=4, 4-1=3).</p>
    <h6><a id="spontaneous-spellcasting"></a>Spontaneous Spellcasting</h6>
Characters who cast all their spells spontaneously - such as bards and sorcerers
-  don't have to prepare spells. They can cast any spell they know by spending the
requisite number of spell points.
    <p>Characters with the ability to cast a limited nimber of spells spontaneously
(such as druids, who can spontaneously cast a <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#summon-natures-ally-i">summon nature's ally</a>
spell in place of another spell of the same level) are always treated as having
those spells prepared, without spending any spell slots to do so. Thus they can
cast such spells any time they have sufficien spell points.</p>
    <p>Under this sytem, the <a style={{
        "color": "#579eb6"
      }} href="clericDomains.html#healing">Healing</a> domain
becomes a relatively poor choice for good-aligned clerics, since they gain less
of a benefit for that domain. See <a style={{
        "color": "#579eb6"
      }} href="unearthedClasses.html#spontaneous-divine-casters">Spontaneous Divine Casters</a> for ways to solve that dilemma.</p>
    <h6><a id="regaining-spell-points"></a>Regaining Spell Points</h6>
Spellcasters regain lost spell ponts whenever they could normally regain spells.
Doing this requires the same amount of rest and preparation or concentration time
as normal for the class. Without this period of rest and mental preparation, the
caster's mind isn't ready to regain its power. Spell points are not divorced from
the body; they are part of it. Using spell points is mentally tiring, and without
the requisite preriod of rest, they do not regenerate. Any spell points spelnt within
the last 8 hours count against a character's daily limit and aren't regained.
    <h5><a id="casting-spells"></a>CASTING SPELLS</h5>
Each spell costs a cetain number of spell points to cast. The higher the level of
the spell, the more points it costs. Table: Spell Point Costs describes each spell's
cost.
    <b>Table: Spell Point Costs</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "30%"
    }}>
      <tbody>
        <tr>
          <th>Spell Level</th>
          <th>Spell Point Cost</th>
        </tr>
        <tr className="odd-row">
          <td>0</td>
          <td>0<sup><sub>1</sub></sup></td>
        </tr>
        <tr>
          <td>1st</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>2nd</td>
          <td>3</td>
        </tr>
        <tr>
          <td>3rd</td>
          <td>5</td>
        </tr>
        <tr className="odd-row">
          <td>4th</td>
          <td>7</td>
        </tr>
        <tr>
          <td>5th</td>
          <td>9</td>
        </tr>
        <tr className="odd-row">
          <td>6th</td>
          <td>11</td>
        </tr>
        <tr>
          <td>7th</td>
          <td>13</td>
        </tr>
        <tr className="odd-row">
          <td>8th</td>
          <td>15</td>
        </tr>
        <tr>
          <td>9th</td>
          <td>17</td>
        </tr>
        <tr>
          <td colSpan="2"> 1 <i>0-level spells cost no spell points to cast. If a spellcaster
is capable of casting 0-level spells, she can cast a number of 0-level spells
each day equal to three + the number of spell points gained by that class at
1st level.</i></td>
        </tr>
      </tbody>
    </table>
    <p>Spellcasters use their full normal caster level for determining the effect of
their spells in this system, with one significant exception. Spells that deal a
number of dice of damage based on caster level (such as <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#magic-missile">magic missile</a>,
      <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#searing-light">searing light</a>, or <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#lightning-bolt">lightning bolt</a>)
deal damage as if cast by a character of the minimum level of the class capable
of casting the spell. Spells whose damage is partially based on caster level, but
that don't deal a number of dice of damage based on caster level (such as <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#produce-flame">produce flame</a>
or an <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#inflict-light-wounds">inflict</a> spell) use the
spellcaster's normal caster level to determine damage. Use the character's normal
caster level for all other effects, including range and duration.</p>
    <p>For example, a <a style={{
        "color": "#579eb6"
      }} href="spellsFtoG.html#fireball">fireball</a> deals a number
of dice of damage based on the caster's level, so when cast by a wizard using this
system, it deals 5d6 points of damage (as if cast by a 5th-level wizard, which is
the minimum level of wizard capable of casting fireball). A sorcerer who casts the
same spell deals 6d6 points of damage, since the minimum level of sorcerer capable
of casting fireball is 6th.</p>
    <p>A character can pay additional spell points to increase the dice of damage dealt
by a spell. Every 1 extra spell point spent at the time increases the spells effective
caster level by 1 for purposes of dealing damage. A character can't increase a damage-dealing
spell's caster level above her own caster level, or above the normal maximum allowed
by the spell.</p>
    <p>For example, even at 7th level, a wizard's <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#lightning-bolt">lightning bolt</a>
deals only 5d6 points of damage (just like a 5th-level wizard) unless she spends
extra spell points. If she spends 1 extra spell point (making the lighting bolt
cost 6 points rather than 5), the spell deals 6d6 points of damage. A second extra
spell point would increase the damage to 7d6 points, but she can't spend more points
than this, since her caster level is only 7th. Were she 10th level or higher, she
could spend a maximum of 5 extra spell points on this spell, raising the damage
up to 10d6, the maximum allowed for a lighting bolt spell.</p>
    <p>Similarly, her <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#magic-missile">magic missile</a> spell
only shoots one missile unless she spends extra spell points. An extra 2 spell points
increases the caster level from 1st to 3rd, granting her one additional missile.
She can spend a maximum of 6 additional spell points in this manner, increasing
her effective caster level to 7th for damage purposes and granting her a total of
four missiles. If she were 9th level or higher, she could spend a maximum of 8 extra
spell points, granting her five missiles (just like a 9th-level caster).</p>
    <h5><a id="metamagic-and-spell-points"></a>METAMAGIC AND SPELL POINTS</h5>
In the spell point system, a DM has two options for how to adjudicate metamagic
effects. In either case, casters need not specially prepare metamagic versions of
their spells - they can simply choose to apply the metamagic effect at the time
of casting. Doing this does not increase the spells casting time.
    <p>The first option is to apply an additional spell point cost to any spell cast
with a metamagic feat. This option allows a character maximum flexibility in her
choice of spellcasting. Effectively, the character must pay for the spell as if
it were a higher-level spell, based on the adjustment from the metamagic feat. If
the metamagic effect(s) would increase the spell's effective level above what she
is capable of casting, she can't cast the spell in that way.</p>
    <p>For example, a 7th level wizard is capable og casting 4th-level spells. She
could empower a 2nd-level spell, or still a 3rd-level spell, or empower and still
a 1st-level spell. She couldn't empower a 3rd-level spell or still a 4th-level spell
(since doing either of those things would raise either spell's effective spell level
to 5th).</p>
    <p>The spell's caster level for purposes of damage-dealing effects (see above) doesn't
change, even if the metamagic effect increases the minimum caster level of that
spell. For instance, a quickened <a style={{
        "color": "#579eb6"
      }} href="spellsFtoG.html#fireball">fireball</a>
still deals damage as if cast by a 5th-level caster unless the caster chooses to
pay additional spell points to increase the caster level.</p>
    <p>For example, if a wizard empowered her <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#magic-missile">magic missile</a>
it would cost her 5 spell points (as if it were a 3rd-level spell) but would shoot
only one missile and deal (1d4+1)*1.5 points of damage. If she spend an additional
6 spell points (for a total of 11), the caster level of the magic missile would
increase to 7th, and the spell would shoot four missiles dealing a total of (4d4+4)*1.5
points of damage.</p>
    <p>The second option is simpler but less flexible. In this option, each selection
of a metamagic feat allows a character to apply the feat's effect three times per
day at no additional spell point cost. The normal limit for maximum spell level
applies (a 7th-level wizard can't empower a spell higher than 2nd level, for instance).</p>
    <p>You could even combine these options, allowing a spellcaster with a metamagic
feat to use the feat three times per day for free, but any additional uses in the
same day would cost extra spell points. Only choose this combination approach if
you're comfortable with characters throwing around a lot of metamagic spells.</p>
    <h5><a id="multiclass-spellcasters"></a>MULTICLASS SPELLCASTERS</h5>
A character with nonstacking spellcasting ability from multiple classes (such as
a cleric/wizard) has a separate pool of spell points for each spellcasting class.
Such characters may only spend spell points on spells granted by that class. Bonus
spell points from a high ability score apply to each pool separately, even if the
same ability score is tied to more than one spellcasting class. In the rare situations
when a character has prepared or knows the same spell in two different slots (such
as a druid/ranger preparing <a style={{
      "color": "#579eb6"
    }} href="spellsDtoE.html#delay-poison">delay poison</a>
as both a 2nd-level druid spell and a 1st-level ranger spell), the character can
cast the spell using either pool of spell points, but the spell is treated as being
cast by a caster of the level of the class from which the spell points are drawn.
    <p>For example, a 5th-level cleric/2nd-level bard has 15 spell points (plus bonus
spell points for high Wisdom) for his cleric spells and 0 spell points (plus bonus
spell points for high Charisma) for his bard spells. When he casts <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#cure-moderate-wounds">cure moderate wounds</a>,
the points for that spell must be drawn from his pool of cleric spell points. If
he knows <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#cure-light-wounds">cure light wounds</a> as a bard
spell and has also prepared it as a cleric spell, he may cast it either as a cleric
or as a bard. As a cleric spell, the spell is cast at 5th level and heals 1d8+5
points of damage; as a bard spell, it is cast at 2nd level and heals 1d8+2 points
of damage.</p>
    <h5><a id="miscellaneous-issues"></a>MISCELLANEOUS ISSUES</h5>
When a character would lose a spell slot (such as from gaining a negative level),
he instead loses the number of spell points required to cast his highest level spell.
    <p>Spells that allow a character to recall or recast a spell don't function in this
system. (It doesn't make any sense to have a spell that gives you more spell points,
since you're either paying more than you get, getting nothing, or getting more than
you paid.) Items that function similarly can work, but differently - they restore
a number of spell points required to cast a spell of that level. A Pearl of Power
for 3rd-level spells, for instance, would restore 5 spell points to a character's
pool of available points when activated.</p>
    <h5><a id="spell-point-variant-vitalizing"></a>SPELL POINT VARIANT: VITALIZING</h5>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79175.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
In the vitalizing system, spellcasters can potentially cast a great number of spells
in a day, but every spell cast is a potential burden on the caster's health and
vitality. Reaching for and directing magical energy is a dangerous and taxing exercise,
at least as difficult as heavy labor or prolonged exertion.
    <p>This variant of the spell point system does not change the way a character prepares
spells, casts spells, regains spell points, or any of the other rules from that
system. However, the spellcaster's pool of spell points represents a physical, not
just mental, limit on his spellcasting power.</p>
    <p>When a spellcaster's spell points pool falls to half of his maximum or less,
he becomes <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#fatigued">fatigued</a>.</p>
    <p>When his spell points drop to one-quarter of his maximum or less, he becomes
      <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#exhausted">exhausted</a>.</p>
    <p>For example, a 1st-level cleric with 3 spell points (2 for his level, +1 bonus
spell point for high Wisdom) enters a fight by casting <a style={{
        "color": "#579eb6"
      }} href="spellsAtoB.html#bless">bless</a>
on his allies, spending 1 of his 3 spell points. Doing this has no ill effect on
him, since he still has more than half his spell points remaining. If, during the
fight, he then casts <a style={{
        "color": "#579eb6"
      }} href="spellsDtoE.html#divine-favor">divine favor</a>, spending
another spell point, he now becomes <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#fatigued">fatigued</a>, since he has only one-third of his
spell points remaining. After the fight, he spontaneously casts <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#cure-light-wounds">cure light wounds</a>
on a wounded party member, spending his last spell point. Not only has he exhausted
his spells for the day, but he has <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#exhausted">exhausted</a>
his body as well.</p>
    <h6><a id="recovering-spell-points"></a>Recovering Spell Points</h6>
In the vitalizing system, spellcasters must rest to recover their spell points and
restore their physical well-being. A character's spell point total is tied directly
to his level of fatigue. If an <a style={{
      "color": "#579eb6"
    }} href="abilitiesAndConditions.html#exhausted">exhausted</a>
character rests for 1 hour, he becomes <a style={{
      "color": "#579eb6"
    }} href="abilitiesAndConditions.html#fatigued">fatigued</a>
- and his spell point total rises to one-third of his maximum (round fractions down).
A second hour of rest increases the spellcaster's spell point total to two-thirds
of his maximum. It takes another 6 hours of rest to replenish the last one-third
of his spell points and shake the physical effects of the spellcasting. Spells that
remove fatigue and exhaustion (such as <a style={{
      "color": "#579eb6"
    }} href="spellsHtoL.html#heal">heal</a> and
    <a style={{
      "color": "#579eb6"
    }} href="spellsPtoR.html#restoration">restoration</a>) leave the recipient with
a spell point total equal to two-thirds of his normal maximum.
    <p>As in the standard rules, a spellcaster mist rest for a full 8 hours before preparing
a fresh allotment of spells for the day. Even if an <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#exhausted">exhausted</a>
spellcaster regains his lost energy and spell points, he can't change the spells
he has prepared without 8 hours of rest.</p>
    <p><b>Mundane Fatigue:</b> If a spellcaster is subject to some other effect that
would make him <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#fatigued">fatigued</a> or <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#exhausted">exhausted</a>,
he loses spell points accordingly. If he becomes fatigued, his spell point total
drops to one-half of his normal maximum (round down), and if her becomes exhausted,
his spell point total drops to one-quarter his normal maximum.</p>
    <h6><a id="optional-vitalizing-variants"></a>Optional Vitalizing Variants</h6>
As a further variant of this system, all spellcasters gain bonus spell points based
on their Constitution scores rather than the ability score that normally grants
bonus spells. This variant reflects the idea that spellcasting power is tied to
the caster's physical health. It also essentially requires spellcasters to have
two high ability scores, though most spellcasters are happy to have a high Constitution
score anyway.
    <p>A second optional variant would allow a spellcaster to exceed his normal pool
of spell points, but at great personal risk. Doing so successfully requires a <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#concentration">Concentration</a>
check (DC 20 + spell level). Each time a character casts a spell for which he does
not have sufficient spell points and subsequently fails the Concentration check,
he takes both lethal and nonlethal damage equal to the level of the spell cast.
A desperate (or unwary) spellcaster can literally cast himself into unconsciousness
in this manner.</p>
    <h5><a style={{
        "color": "#579eb6"
      }} href="behind-the-curtain-spell-points"></a>BEHIND THE CURTAIN: SPELL POINTS</h5>
    <div style={{
      "marginLeft": "40px"
    }}>The spell point system dramatically expands the versality of a spellcaster.
Since she's no longer tied to using a specific number of spell slots, she can much
more easily adapt to situations. In effect, spell points make all classes work more
like the sorcerer, and make the sorcerer (or bard) work even more like the sorcerer.
In general, spellcasters become more powerful - though they aren't capable of casting
any spell they couldn't cast before, they are now capable of casting more high-level
spells per day and more of whichever spells they need. If a 15th-level cleric needs
to cast <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#heal">heal</a> a dozen times during an adventure,
he can do that (though not much else).
      <p>One balancing factor is the cost for casters to increase the damage dealt by
their spells. This cost helps to maintain balance between spells of different level.
If you didn't have to pay more for a 5d6 <a style={{
          "color": "#579eb6"
        }} href="spellsHtoL.html#lightning-bolt">lightning bolt</a>
(a 3rd-level spell costing 5 spell points), then the 9d6 lightning bolt would cost
barely more than half as much as a 9d6 <a style={{
          "color": "#579eb6"
        }} href="spellsC.html#cone-of-cold">cone of cold</a>
(a 5th-level spell costing 9 spell points), even though both spells deal equal damage.</p>
      <p>If you use this variant, consider adding other game elements that influence (or
are influenced by) spell points. These might include magic items that grant (or
cost) spell points, feats that grant bonus spell points (or make certains spells
cost fewer spell points to cast), special abilites that drain spell points from
casters, and so forth.</p></div>
    <h5><a id="house-rule-daily-spell-list"></a>HOUSE RULE: DAILY SPELL LIST</h5>
    <div style={{
      "marginLeft": "40px"
    }}>This variant spontaneous casting system is designed for spellcasters who normally
prepare their spells in advance (including clerics, druids, paladins, rangers, and
wizards). A spellcaster using this variant prepares the same number of spells per
spell level as normal. However, instead of preparing the exact combination of spells
that she can cast that day (such as two <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#magic-missile">magic missile</a>
spells and one <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#mage-armor">mage armor</a> spell for a 2nd-level
wizard with Int 14), the spellcaster prepares a list of spells for each spell level
from which she can spontaneously cast as she chooses.
      <p>For example, that 2nd-level wizard with Int 14 would prepare four different 0-level
spells and three different 1st-level spells. During the day, she can cast any combination
of those four 0-level spells a total of four times, and any combination of those
three 1st-level spells a total of three times. In effect, the caster's list of prepared
spells is treated like a sorcerer's list of spells known.</p>
      <p>Unlike what a sorcerer can do, a spellcaster using this system can't cast a lower-level
spell in place of a higher-level spell. If you use this variant, decide whether
clerics and druids retain their normal spontaneous casting options (<a style={{
          "color": "#579eb6"
        }} href="spellsC.html#cure-light-wounds">cure</a>
or <a style={{
          "color": "#579eb6"
        }} href="spellsHtoL.html#inflict-light-wounds">inflict</a> spells for clerics,
        <a style={{
          "color": "#579eb6"
        }} href="spellsS.html#summon-natures-ally-i">summon nature's ally</a> spells for
druids). You can either rule that the character must prepare the spell in question
to have it available for spontaneous casting, or that such spells are always available
for spontaneous casting (as if the character prepared it "for free").</p>
      <p>Bards and sorcerers obviously can't use this system, since they already have
their own spontaneous casting method. For bards, that's not a big deal - their spellcasting
powers are only a portion of their class features, so no real change is merited.
Sorcerers still have an advantage over wizards in that they can cast more spells
per day, but the versality gained by the wizard definitely infringes on the sorcerer's
power level. To compensate, consider granting the sorcerer one additional spell
per day for every spell level except his highest. A 1st-level sorcerer would thus
be able to cast one extra 0-level spell each day, while a 6th-elvel sorcerer would
be able to cast one additional 0-, 1st-, and 2nd-level spell.</p>
      <p>In the last 2nd Edition AD&#38;D campaign I ran, I incorporated this spontaneous
casting variant rule for all spellcasters in the campaign. In some ways, the system
looks similar to the method a sorcerer uses to cast his daily allotment of spells,
but it allows greater flexibility than that typically enjoyed by a sorcerer.</p></div>
    <div style={{
      "marginLeft": "80px"
    }}><i>-Andy Collins</i></div>
    <h5><a id="house-rule-simplified-npc-spellscasters"></a>HOUSE RULE: SIMPLIFIED NPC SPELLCASTERS</h5>
    <div style={{
      "marginLeft": "40px"
    }}>I enjoy running high-level NPC spellcasters because they have the potential to challenge
and surprise my players. Sometimes, however, I feel as if I'm not playing the evil
wizard or vile cleric up to his potential. These characters are supposed to be incredibly
intelligent or at least frighteningly intuitive, yet when I choose their spells
before the adventure begins, I can't always anticipate choices my players will make.
So I cheat... just a bit.
      <p>When I create a villainous NPC spellcaster who prepares spells, I write down the
spells the character has prepared, but only the top two or three levels' worth.
Then I pick one or two spells of every level lower than that to simulate spells
my evil wizard, cleric, or druid would like to focus on. Finally, I record the number
of spell slots, by level, the character has left open. I allow my NPC to use these
slots to cast spells "on the fly," assuming that my incredibly intelligent or wise
mastermind prepared "just the right spell" for whatever situation migh come up.
Here's how a sample NPC 9th-level wizard's spell selection might look, assuming
an Intelligence score of 22:</p>
      <p><i>Wizard Spells Prepared</i> (4/6/6/4/3/2; save DC 16 + spells level): 0 - <i>daze</i>,
3 open slots; 1 - <i>hold portal, magic missile, protection from good,</i> 3 open
slots; 2 - <i>alter self, detect thoughts, false life,</i> 3 open slots; 3  - <i>fireball,
hold person,</i> 2 open slots; 4 - <i>globe of invulnerability, lesser, ice storm,
polymorph</i>; 5 - <i>cloudkill, baleful polymorph</i>.</p>
      <p>No spells are duplicated. Some spells (particularly lower-level ones) might not
be big combat spells, but probably fit into the theme of the character or the situation.
I want my NPC to be flexible, but not overpowering. He can still cast <i> magic missile</i>
four times if he wants, but not six.</p>
      <p>If I give metamagic feats to an NPC, I don't let him cast metamagic spells "on
the fly." Those spells require special preparedness, so if I want my NPC wizard
to have a stilled <i>teleport</i> or a quickened <i>magic missile</i>, I put it
in an appropriate spell slot.</p></div>
    <div style={{
      "marginLeft": "80px"
    }}><i>-Ed Stark</i></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      